import React, {Component} from 'react'
import { EmbeddedWidgetLayout } from '../../components/layouts'
import { withPreview } from '../../components/common'

class EmbeddedWidgetPreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            sitemap: []
        }
    };

    componentDidMount() {
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('widgetEmbedded', this.props.location.search).then(entry => {
            this.initializeEmbeddedWidget(entry);
        })
    }

    initializeEmbeddedWidget = (entry) => {
        const data = {
            __typename: 'ContentfulWidgetEmbedded',
            ...entry.fields
        }

        this.setState({
            data
        })
    }
    
    render(){
        const { data, sitemap } = this.state

        return (data && <EmbeddedWidgetLayout data={data} sitemap={sitemap}/>)
    };
};

export default withPreview(EmbeddedWidgetPreview);
